<template>
  <div>
    <div class="content">
      <div class="contentTop">
        <div class="contentTopTitle">操作日志</div>
        <div class="contentTopRight">
          <div class="block">
            <el-date-picker v-model="selectDate" @change="ChangeTime" type="daterange" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" default-time>
            </el-date-picker>
          </div>
          <el-input placeholder="" suffix-icon="el-icon-search" size="small" v-model="input2" @change="searchHandler">
          </el-input>
        </div>
      </div>
      <el-table ref="multipleTable" :data="tempData" tooltip-effect="dark" style="width: 100%">
        <el-table-column prop="operatorName" label="操作名称">
        </el-table-column>
        <el-table-column prop="operatorResult" label="操作结果">
          <template slot-scope="scope">
            <span
              :class="{ 'cell_Red': (scope.row.operatorResult === '失败'), 'cell_Green': (scope.row.operatorResult === '成功') }"></span>
            <span style="margin-left: 10px" v-if="(scope.row.operatorResult === '失败')">失败</span>
            <span style="margin-left: 10px" v-else-if="(scope.row.operatorResult === '成功')">成功</span>
          </template>
        </el-table-column>
        <el-table-column prop="modifyTime" label="操作时间">
        </el-table-column>
        <el-table-column prop="modifyUser" label="操作员">
        </el-table-column>
        <el-table-column prop="operatorIp" label="IP">
        </el-table-column>
      </el-table>
      <div class="totalFlex">
        <span>共{{ total }}条</span>
        <span>
          <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
            :current-page="currentPage1" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size=pageChange
            layout="prev, pager, next,sizes, jumper" :total=total>
          </el-pagination>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getLogsData, searchLogsData } from '@/api/logs.js'
export default {
  name: "scheduling",
  data() {
    return {
      tableData: [],
      total: 0,
      multipleSelection: [],
      currentPage1: 1,
      input2: '',
      tempData: [],
      section: [
        '已确认',
        '未确认'
      ],
      pageChange: 10,
      tempTotal: 0,
      searchStatus: false,
      searchData: [],
      selectDate: [],
      timeStatus: false,
      timeData: [], //选择时间后的数据
    }
  },
  methods: {
    // 选择日期
    async ChangeTime() {
      let startTime = Date.parse(new Date(this.selectDate[0]).toString())
      let endTime = Date.parse(new Date(this.selectDate[1]).toString())
      const res = await searchLogsData(1, this.tempTotal, this.input2,startTime, endTime)
      this.timeData = res.data.records
      this.total = res.data.total
      this.tempData = this.timeData.filter((v, i) => i < 10)
      this.timeStatus = true
    },
    // 分页组件--一页显示多少条数据
    async handleSizeChange(val) {
      this.pageChange = val
      if (this.searchStatus) {
        this.tempData = this.searchData.filter((v, i) => i < val)
      } else if (this.timeStatus) {
        this.tempData = this.timeData.filter((v, i) => i < val)
      } else {
        const res = await getLogsData(1, val)
        const { records } = res.data
        this.tableData = records
        this.tempData = this.tableData
      }
      this.currentPage1 = 1
    },
    // 分页组件--选择页数
    async handleCurrentChange(val) {
      if (this.searchStatus) {
        this.tempData = this.searchData.slice(val * this.pageChange - this.pageChange, val * this.pageChange)
      } else if (this.timeStatus) {
        this.tempData = this.timeData.slice(val * this.pageChange - this.pageChange, val * this.pageChange)
      } else {
        const res = await getLogsData(val, this.pageChange)
        this.currentPage1 = val
        const { records } = res.data
        this.tableData = records
        this.tempData = this.tableData
      }
      this.currentPage1 = val
    },
    async searchHandler() {
      let searchValue = this.input2
      const res = await searchLogsData(1, this.tempTotal, searchValue,Date.parse(new Date(this.selectDate[0]).toString()) || '',Date.parse(new Date(this.selectDate[1]).toString()) || '')
      // 搜索后的总条数
      this.searchStatus = true
      this.searchData = res.data.records
      this.pageChange = 10
      this.currentPage1 = 1
      this.tempData = this.searchData.filter((v, i) => i < 10)
      this.total = this.searchData.length
    },
    async renovate() {
      // 刷新，重新赋值
      const res = await getLogsData(1, 10)
      const { records } = res.data
      this.tableData = records
      this.total = res.data.total
      this.tempData = this.tableData
      this.tempTotal = res.data.total
      this.pageChange = 10
      this.currentPage1 = 1
      this.searchStatus = false
      this.input2 = ''
      this.selectDate = []
    }
  },
  async activated() {
    await this.renovate()
  }
}
</script>

<style lang="sass" scoped>
@import './style/style.scss'
</style>

